import React from 'react'
import Image from 'gatsby-image'
import Content from 'components/Content'

import './SplitBanner.scss'
import quoteIcon from 'img/quote.svg'

const SplitBanner = ({ image, content, quote }) => {
  return (
    <div className="SplitBanner row mx-0">
      <div className="col-lg-6 px-0">
        <Image
          className="h-100"
          objectFit="cover"
          fluid={image.localFile.childImageSharp.fluid}
        />
      </div>

      <div className="col-lg-6 py-5 px-0 green-part color-background-contrast pl-lg-3 d-flex flex-column justify-content-center">
        <div className="SplitBanner-content ml-lg-3 p-5 col-xl-8">
          {quote && (
            <div>
              <img className="mb-4" src={quoteIcon} alt="" />
            </div>
          )}

          <Content content={content} />

          {quote && (
            <div className="text-right mt-3">
              <p className="text-right font-weight-bold mb-0 text-uppercase">{quote.function}</p>
              <p className="text-right font-weight-bold">{quote.name}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default SplitBanner