/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { graphql, Link } from 'gatsby'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

// Components
import Layout from 'components/Layout'
import Banner from 'components/Banner/Banner'
import Content from 'components/Content'
import SEO from 'components/SEO'
import SplitBanner from 'components/SplitBanner'

// CSS
import 'styles/ServicesTemplate.scss'

// Images
import chevronDown from 'img/chevron-down.svg'
import chevronRight from 'img/chevron-right.svg'
import chevronLeft from 'img/chevron-left.svg'

// Third Party
import Slider from 'react-slick'

const Services = ({ data, pageContext }) => {
  const {
    wordpressPage: {
      title,
      acf: {
        banner,
        content,
        services,
        cycle,
        splitted_banner: splitBanner,
      },
    },
  } = data

  return (
    <Layout>
      <SEO title={title} />
      <Banner image={banner.image} title={title} />
      <section className="container my-3 mb-sm-3 mb-lg-5">
        <div className="row">
          <div className="col">
            <Breadcrumb crumbSeparator={' > '} crumbs={pageContext.breadcrumb.crumbs} crumbLabel={title} />
          </div>
        </div>
      </section>

      <section className="container services-content mb-lg-5 mb-3">
        <div className="row">
          <Content content={content} className="col-xl-8 mb-md-5" />
        </div>
      </section>

      <section className="services-about mb-5 color-background-secondary py-4 py-lg-5">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-9 col-lg-6 mb-3 mb-lg-5">
              <h2 className="font-size-xl font-weight-xl text-center">{services.title}</h2>
            </div>
          </div>

          <div className="row justify-content-center services-taxonomy-row">
            {services.related_services.map((service, index) => (
              <div className="col-md-12 col-lg-4 col-xl-3 px-5 px-lg-4 px-xl-3 mb-lg-3 mb-2 services-taxonomy" key={index}>
                <Link to={service.link.url} className="card py-3 px-2 px-lg-5 py-lg-5 p-lg-5">
                  <div className="card-icon-container">
                    <img className="card-icon" src={service.image.localFile.publicURL} alt="" />
                  </div>
                  <strong className="card-title px-2 text-lowercase">
                    <Content content={service.link.title} />
                  </strong>
                  <img className="card-chevron" src={chevronDown} alt="" />
                </Link>
                <div className="services-descendants py-1 pt-3 rounded color-background-contrast">
                  <div>
                    {service.descendants.map((descendant, key) => (
                      <Link className="w-100" key={key} to={descendant.link.url}>
                        <Content content={descendant.link.title} className="font-family-main mb-2 font-size-m font-weight-s text-center color-light" />
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="cycle mb-5">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 d-flex justify-content-center align-items-center">
              <img className="w-100 h-100" src={cycle.localFile.publicURL} alt="Cyclus" />
            </div>
          </div>
        </div>
      </section>

      <section className="split-banner mt-5">
        <Slider
          infinite
          arrows
          nextArrow={<button type="button" className="slick-next"><img alt="" src={chevronRight} /></button>}
          prevArrow={<button type="button" className="slick-prev"><img alt="" src={chevronLeft} /></button>}
          speed={1000}
          slidesToShow={1}
          slidesToScroll={1}
          autoplay
          autoplaySpeed={8000}
        >
          {splitBanner.map((item, index) => (
            <SplitBanner key={index} image={item.left} content={item.right} />
          ))}
        </Slider>
      </section>
      {
        // <SplitBanner image={splitBanner.left} content={splitBanner.right} />
      }
    </Layout>
  )
}

export const pageQuery = graphql`
	query ServicesQuery($wordpress_id: Int!) {
		wordpressPage(
			wordpress_id: { eq: $wordpress_id }
		) {
			id
			wordpress_id
			title
			slug
			acf {
				banner {
					image {
						localFile {
							childImageSharp {
								fluid(quality: 99, maxWidth: 1400) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}

				content

				services {
					title
					related_services {
						link {
							title
							url
						}
						image {			
              localFile {
                publicURL
              }
						}
						descendants {
							link {
								url
								title
							}
						}
					}
				}

				cycle {		
          localFile {
            publicURL
          }
				}
				splitted_banner {
					left {
						localFile {
							childImageSharp {
								fluid(quality: 100, maxWidth: 900) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
					right
				}
			}
		}
	}
`

export default Services